import { Link } from "react-router-dom";

export default function UxFooter() {
  return (
    <footer className="">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="text-center mb-3 footer-legal">
          Copyright 2023 Simeon Networks. All Rights Reserved
          <Link
            to="https://www.simeonnetworks.com/legal/privacy"
            className="ml-2"
          >
            Privacy
          </Link>
          <Link
            to="https://www.simeonnetworks.com/legal/terms"
            className="ml-2"
          >
            Terms
          </Link>
        </div>
      </div>
    </footer>
  );
}
