import { Outlet } from "react-router-dom";
import UxSidebar from "./uxSidebar";
import UxHeader from "./uxHeader";
import UxFooter from "./uxFooter";

export default function UxBaseline() {
  return (
    <>
      <div className="App dark:bg-slate-800 dark:text-gray-100">
        <UxSidebar />
        <div className="app-main w-full flex flex-col">
          <UxHeader />
          <main className="flex flex-col grow">
            <Outlet />
          </main>
          <UxFooter />
        </div>
      </div>
    </>
  );
}
