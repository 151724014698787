import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UxSidebar() {
  let menuItems = [];
  return (
    <div className="ux-sidebar">
      <nav className="sidebar-top bg-light leading-[3rem] bg-[#c8beb6]">
        <div>
          <i className="fas fa-th fa-lg"></i>&nbsp;
          <span className="sr-only">Application Selector</span>
        </div>
      </nav>
      <nav className="sidebar-main">
        <ul className="nav flex-column flex-wrap pl-0 my-0 list-none">
          {menuItems.map((item, itemIdx) => (
            <li key={itemIdx}>
              <Link
                className="nav-link block px-4 py-2 text-[#c8beb6]"
                to={item.path}
              >
                {item.icon ? (
                  <>
                    <FontAwesomeIcon icon={item.icon} />
                  </>
                ) : (
                  <>{item.title[0]}</>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
